import React, { Component, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Header from './features/template/header'
import Preloader from './features/template/preloader'
import Quiz from './features/quiz/quiz'
import Footer from './features/template/footer'
import Login from './features/login/login'
import Result from './features/result/result'
import Page404 from './features/template/404'
import Page403 from './features/template/403'
import Application from './features/application/application'
import Register from './features/register/register'
import Home from './features/home/home'
import { useKeycloak } from '@react-keycloak/web'

const AppFactory = (props) => {

    const { keycloak } = useKeycloak()
    const history = useHistory()

    const [state, setState] = React.useState({
        isLoading: true,
        auth: {
            user: null,
            userToken: null
        },
        contentType: props.contentType,
        contentData: null
    })

    const [ restarted, setRestarted ] = useState(false)

    const authenticateCandidate = () => {

        const user = JSON.parse(localStorage.getItem('user'))

        setState({
            ...state,
            auth: {
                user: user.data.user,
                userToken: user.data.access_token
            },
            contentType: 'quiz',

        })
    }

    const getContent = () => {

        switch (state.contentType) {
            case 'home':
                return <Home clientInfo={props.clientInfo} changeContent={changeContent} />

            case 'login':
                return <Login />

            case 'quiz':
                return <Quiz urlParamsObj={props.urlParamsObj} restarted={restarted} handleRestart={handleRestart} changeContent={changeContent} auth={state.auth} />

            case 'results':
                return <Result urlParamsObj={props.urlParamsObj} contentData={state.contentData} />

            case 'application':
                return <Application urlParamsObj={props.urlParamsObj} handleRestart={handleRestart} changeContent={changeContent} />

            case 'register':
                return <Register clientInfo={props.clientInfo} changeContent={changeContent} authenticateCandidate={authenticateCandidate} />

            case '404':
                return <Page404 />

            case '403':
                return <Page403 />

            case '500':
                return <h1>Internal Error - 500</h1>
        }
    }

    const changeContent = (contentType, props) => {
        setState({
            ...state,
            contentType: contentType,
            contentData: props
        })
    }

    const handleRestart = () => {
        setRestarted(true)
    }

    switch (state.contentType) {
        case '404':
            return (
                <div class="notranslate">
                    {getContent()}
                </div>
            )

        case 'home':
            return (
                <div class="notranslate">
                    <Header urlParamsObj={props.urlParamsObj} quizStatus={props.quizStatus} clientInfo={props.clientInfo} quizId={props.quizId} />
                    <Preloader />
                    {getContent()}
                    <Footer />
                </div>
            )

        case 'register':
            return (
                <div class="notranslate">
                    <Preloader />
                    <Header urlParamsObj={props.urlParamsObj} quizStatus={props.quizStatus} clientInfo={props.clientInfo} quizId={props.quizId} />
                    {getContent()}
                    <Footer />
                </div>
            )
        case 'login':
            return (
                <div class="notranslate">
                    <Preloader />
                    <Header urlParamsObj={props.urlParamsObj} quizStatus={props.quizStatus} clientInfo={props.clientInfo} quizId={props.quizId} />
                    <Login clientInfo={props.clientInfo} quizId={props.quizId} authenticateCandidate={authenticateCandidate} changeContent={changeContent} />
                    <Footer />
                </div>
            )
        default:
            if (keycloak.authenticated) {

                return (
                    <div class="notranslate">
                        <Header urlParamsObj={props.urlParamsObj} quizStatus={props.quizStatus} clientInfo={props.clientInfo} quizId={props.quizId} />
                        <Preloader />
                        {getContent()}
                        <Footer />
                    </div>
                )
            } else {
                keycloak.login()
                return <div></div>
            }
    }

    

}

export default AppFactory
