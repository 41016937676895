import React from 'react'
import './question.css'
import Countdown from './countdown'
import { Trans } from 'react-i18next'
import ModalImage from "react-modal-image";

export default function question(props) {

    const questionDescriptionCss = () => {
        if (props.questionData.image) {
            return 'section__desc question-wording question-description text-color-rgba mb-0 pt-3 qestion-image'
        } else {
            return 'section__desc question-wording question-description quiz-desc-no-image text-color-rgba mb-0 pt-3'
        }
    }

    const questionMedia = () => {
        if(props.questionData.image){
            return(
                <ModalImage className={'small-question-image'} small={props.questionData.image} large={props.questionData.image}/>
            )
        }
        return(<></>)
    }

    return (
        <div className='question-container'>
            <div className='row col-12 justify-content-between'>
                <h2 className="section__title text-white"><Trans>Question</Trans> {props.questionData.order} <Trans>of</Trans> {props.questionData.amountQuestions}</h2>
                <p className="lead text-white font-size-25 font-weight-medium mb-0 align-self-center"><Countdown instructions={props.questionData.instructions} timer={props.questionData.time} handleAnswerCadidateTime={props.handleAnswerCadidateTime} nextQuestion={props.nextQuestion} /></p>
            </div>
            <div className='row'>
                <div className='quiz-content col-12'>
                    <div className='question-area'>
                        <p className={questionDescriptionCss()}><div dangerouslySetInnerHTML={{ __html: props.questionData.description }}></div></p>
                        <div className='question-image'>
                            {questionMedia()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}