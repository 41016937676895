import React, { Component } from 'react'

import InstructionsModal from './instructionsModal'

export default class Countdown extends Component {

    constructor(props) {
        super(props)

        this.state = {
            minutes: 0,
            seconds: this.props.timer,
            started: false,
            stopped: false
        }
        this.startTimer = this.startTimer.bind(this)
    }

    startTimer() {
        if (this.state.started == false) {
            this.setState({
                ...this.state,
                started: true
            })
            this.myInterval = setInterval(() => {
                const { seconds, minutes } = this.state
                if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                        seconds: seconds - 1
                    }))
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        if (this.props.timer > 0)
                        this.props.nextQuestion(null, false, true)
                    } else {
                        this.setState(({ minutes }) => ({
                            minutes: minutes - 1,
                            seconds: 59
                        }))
                    }
                }
            }, 1000)
        }
    }

    componentDidMount() {
        if (this.props.instructions) {
            clearInterval(this.myInterval);
        } else {
            this.startTimer();
        }
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            seconds: nextProps.timer,
        })

        if (nextProps.instructions) {
            this.setState({
                ...this.state,
                seconds: nextProps.timer,
                started: false
            })
            clearInterval(this.myInterval)
        }
    }

    render() {
        const { seconds } = this.state
        this.props.handleAnswerCadidateTime(seconds)
        const counterHours = seconds >= 3600 ? parseInt(seconds / 3600) : 0;
        const counterMinutes = (seconds % 3600) >= 60 ? parseInt((seconds % 3600) / 60) : 0;
        const counterSeconds = ((seconds % 3600) % 60);
        const result = (counterHours < 10 ? '0' + counterHours : counterHours) + ':'
            + (counterMinutes < 10 ? '0' + counterMinutes : counterMinutes) + ':'
            + (counterSeconds < 10 ? '0' + counterSeconds : counterSeconds)

        return (
            <>
                <span>{this.props.timer > 0 ? result : ''}</span>
                {this.props.instructions ? <InstructionsModal stopTimer={this.stopTimer} startTimer={this.startTimer} instructions={this.props.instructions} /> : ''}
            </>

        )
    }
}
