import React, { Component } from 'react'
import $ from 'jquery'
import Question from './question'
import Answer from './answer'
import Instruction from '../instructions/instructions'
import HttpRequests from '../../api/httpRequests'


let answerSpentTime = 0
let candidateSelectedAnswer = null

export default class Quiz extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            quizStarted: false,
            quiz: {},
            question: {
                amountQuestions: null,
                allowSkip: null,
                createdAt: null,
                description: null,
                image: null,
                instructions: null,
                knowledgeArea: null,
                order: null,
                quiz: null,
                time: null,
                type: null,
                updatedAt: null,
                uuid: null,
                weight: null,
                answers: null

            }
        }

        this.nextQuestion = this.nextQuestion.bind(this)
        this.startQuiz = this.startQuiz.bind(this)
        this.handleAnswerCadidateTime = this.handleAnswerCadidateTime.bind(this)
        this.checkAdditionalFields = this.checkAdditionalFields.bind(this)

    }

    async getQuiz() {
        $('.preloader').show();

        try {
            const response = await HttpRequests.retrieveQuiz(localStorage.getItem('quiz_id'))

            switch (response.status) {
                case 200:
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        quiz: response.data.data
                    })
                    break

                case 206:
                    this.checkAdditionalFields(response.data.data.additional_fields.length)
                    break
            }

        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        window.location.reload();
                        break
    
                    case 404:
                        this.setState({
                            ...this.state,
                            isLoading: false,
                            content: '404'
                        })
                        break
    
                    default:
                        window.location.reload();
                }
            }

            localStorage.removeItem("quiz_id")

        } finally {
            $('.preloader').delay('500').fadeOut(1000);
        }
    }

    async checkAdditionalFields(additionalFields) {
        $('.preloader').show();
        if (additionalFields > 0) {
            this.props.changeContent('application', null);

            if (this.props.urlParamsObj.urlString) {
                window.history.replaceState(null, 'Application', '/' + localStorage.getItem('quiz_id') + '/application' + this.props.urlParamsObj.urlString)
            } else {
                window.history.replaceState(null, 'Application', '/' + localStorage.getItem('quiz_id') + '/application')
            }

        } else {
            try {

                const response = await HttpRequests.realizeApplication({})
                if (response.status == 204) {
                    this.getQuiz()
                    window.history.replaceState(null, "Application", localStorage.getItem('quiz_id'))
                }

            } catch (error) {
                window.location.reload()

            } finally {
                $('.preloader').delay('500').fadeOut(1000);
            }
        }

    }

    async startQuiz() {

        $('.preloader').show()

        try {

            const response = await HttpRequests.retrieveQuestion(localStorage.getItem('quiz_id'))

            switch (response.status) {
                case 200:
                    this.setState({
                        ...this.state,
                        quizStarted: true,
                        question: {
                            amountQuestions: this.state.quiz.amount_questions,
                            allowSkip: response.data.data.allow_skip,
                            createdAt: response.data.data.created_at,
                            description: response.data.data.description,
                            image: response.data.data.image,
                            instructions: response.data.data.instructions,
                            knowledgeArea: response.data.data.knowledge_area,
                            order: response.data.data.order,
                            quiz: response.data.data.quiz,
                            time: response.data.data.time,
                            type: response.data.data.type,
                            updatedAt: response.data.data.updated_at,
                            uuid: response.data.data.uuid,
                            weight: response.data.data.weight,
                            answer_character_limit: response.data.data.answer_character_limit,
                            answers: response.data.data.answers
                        }

                    })

                    if (this.props.urlParamsObj.urlString) {
                        window.history.replaceState(null, 'Questions', '/' + localStorage.getItem('quiz_id') + '/questions' + this.props.urlParamsObj.urlString)
                    } else {
                        window.history.replaceState(null, 'Questions', '/' + localStorage.getItem('quiz_id') + '/questions')
                    }

                    break

                case 204:
                    if (this.props.urlParamsObj.urlString) {
                        window.history.replaceState(null, 'Results', '/' + localStorage.getItem('quiz_id') + '/results' + this.props.urlParamsObj.urlString)
                    } else {
                        window.history.replaceState(null, 'Results', '/' + localStorage.getItem('quiz_id') + '/results')
                    }
                    
                    this.props.changeContent('results', this.state.quiz);
                    break
            }

        } catch (error) {
            this.props.changeContent('500', null);

        } finally {
            $('.preloader').delay('500').fadeOut(1000);
        }

    }

    handleAnswerCadidateTime(timeLeft, selectedAnswer) {
        if (timeLeft) {
            answerSpentTime = this.state.question.time - timeLeft
        }

        if (selectedAnswer) {
            candidateSelectedAnswer = selectedAnswer
        }
    }

    async nextQuestion(answerId, skipped, timeout) {
        $('.preloader').show()
        const answerData = {
            question: this.state.question.uuid,
            answer: candidateSelectedAnswer,
            time: answerSpentTime,
            skipped: skipped,
            timeout: timeout
        }

        try {

            await HttpRequests.postAnswer(answerData)

            answerSpentTime = 0
            candidateSelectedAnswer = null
            this.startQuiz()

        } catch (error) {
            switch (error.response.status) {
                case 401:
                    window.location.reload();
                    break
            }

        } finally {
            $('.preloader').delay('500').fadeOut(1000);
        }

    }

    componentDidMount() {
        this.getQuiz()
    }

    render() {

        if (this.state.isLoading == true) {
            return (
                <div></div>
            )
        }

        if (this.state.quizStarted === true) {
            return (
                <div>
                    <Question
                        questionData={this.state.question}
                        handleAnswerCadidateTime={this.handleAnswerCadidateTime}
                        nextQuestion={this.nextQuestion}
                    />
                    <Answer
                        questionData={this.state.question}
                        handleAnswerCadidateTime={this.handleAnswerCadidateTime}
                        nextQuestion={this.nextQuestion}
                    />
                </div>
            )
        } else {
            return (
                <div>
                    <Instruction
                        quizData={this.state.quiz}
                        instructions={this.state.quiz.instructions}
                        title={this.state.quiz.title} start={this.startQuiz}
                        checkAdditionalFields={this.checkAdditionalFields}
                        handleRestart={this.props.handleRestart}
                        restarted={this.props.restarted}
                        urlParamsObj={this.props.urlParamsObj}
                    />
                </div>
            )
        }
    }
}