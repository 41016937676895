import React, { useState } from 'react'
import { Component } from 'react';
import { Button, Modal } from 'react-bootstrap'
import './instructionsModal.css'

export default class InstructionModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            show: false
        }

        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)

    }

    handleShow() {
        this.setState({
            ...this.state,
            show: true
        })
    }

    handleClose() {
        this.setState({
            ...this.state,
            show: false
        })
        this.props.startTimer()
    }

    componentDidMount() {
        if (this.props.instructions) {
            this.handleShow()
        }
    }

    render() {
        return (
            <>
                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                    backdropClassName='background-white'
                >
                    <Modal.Header>
                        <Modal.Title>Atenção!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text_body">
                    <div dangerouslySetInnerHTML={{ __html: this.props.instructions }} class="notranslate"></div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={this.handleClose}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}


